@import '../../variables';

.lesson-view {

  .current {
    background: red;
  }

  .list-group-item.active {
    background-color: rgba($info, 0.9);
    border-color: $info;
  }
}
