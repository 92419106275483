.loading-view {
  .center-vertically {
    margin-top: calc(40vh - 5rem);
  }

  .spinner {
    width: 5rem;
    height: 5rem;
  }
}
