@import '../../variables';

.course-details {
  hr {
    border-top: solid 1px lighten($primary, 10%);
  }

  .spacious-list li {
    line-height: 2.5;
  }

  .video-js {
    width: auto;
    height: 300px;
  }
}

