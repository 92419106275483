.navbar {
  padding-left: 0;
  padding-right: 0;

  .navbar-nav {
    &:first-child {
      .nav-item {
        a {
          padding-left: 0;
        }
      }
    }

    &:last-child {
      .nav-item {
        a {
          padding-right: 0;
        }
      }
    }
  }
}

.nav-bar-shadow {
  $height: 10px;
  $shadow-color: rgba(0, 0, 0, 0.1);
  $shadow: inset 0 $height $height 0 $shadow-color;

  height: 2*$height;
  width: 100%;

  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
