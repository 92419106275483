.landing-view {
  @import url('https://fonts.googleapis.com/css2?family=Sriracha&display=swap');
  @import '../../variables';

  $green: $success;

  header {
    h1 {
      margin-top: 8%;
    }
  }

  .font-sriracha {
    span, h1, h3, .pt-2 {
      font-family: 'Sriracha', cursive;
      color: $green;
    }
  }

  .title-line {
    position: relative;
    text-transform: none;

    &:before {
      content: '';
      width: 100%;
      border-bottom: 1px solid $green;
      height: 1px;
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -1px;
    }

    span {
      position: relative;
      display: inline-block;
      background: #fff;
      padding: 0 30px;
    }
  }

  .btn-outline-success {
    color: $green;
    border-color: $green;

    &:hover {
      color: #fff;
      background-color: $green;
      border-color: $green;
    }
  }

  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: $green;
    border-color: $green;
  }

  .card-container {
    -webkit-perspective: 900px;
    -moz-perspective: 900px;
    -o-perspective: 900px;
    perspective: 900px;
    width: 300px;
    position: relative;
    margin-top: 90px;
  }

  .padding-bottom-card {
    padding-bottom: 200px;
  }

  .skewed-card:nth-child(0) {
    z-index: 6;
  }

  .skewed-card:nth-child(1) {
    z-index: 5;
  }

  .skewed-card:nth-child(2) {
    z-index: 4;
  }

  .skewed-card:nth-child(3) {
    z-index: 3;
  }


  .card-component {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
    height: 300px;

    .front {
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -o-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform: rotateY(-35deg);
      -moz-transform: rotateY(-35deg);
      -o-transform: rotateY(-35deg);
      transform: rotateY(-35deg);
      position: absolute;
      top: 0;
      left: 0;
      background-color: #FFF;
      width: 100%;
      cursor: pointer;
      box-shadow: 10px 4px 14px rgba(0, 0, 0, 0.12);
      border-radius: 10px;
      overflow: hidden;
    }
  }
}
