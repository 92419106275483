.home-view {
  @import '~bootstrap/scss/functions';
  @import '~bootstrap/scss/variables';
  @import '~bootstrap/scss/mixins/breakpoints';
  @import '~bootstrap/scss/mixins/grid-framework';
  @import '~bootstrap/scss/mixins/grid';
  @import '~bootstrap/scss/utilities/spacing';
  @import '~bootstrap/scss/grid';

  margin-top: 1em;

  .header-in-container {
    @extend .container
  }

  .header-in-container-fluid {
    @extend .container-fluid
  }

  header {
    @include media-breakpoint-down(sm) {
      @extend .header-in-container-fluid;
      margin-top: -35px;
      z-index: -1;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      .row {
        margin-left: 0;
        margin-right: 0;

        .col {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
    @include media-breakpoint-up(md) {
      @extend .header-in-container ;
    }
  }

  .section-title {
    @extend .mt-5;
    @extend .mb-5;
  }

  .card-shadow:hover {
    box-shadow: 0 8px 6px -6px black;
  }

  .lead {
    font-size: 2rem;
  }

  .l-spacing {
    letter-spacing: .5rem;
  }

  .card-img-overlay {
    top: 10rem;
    left: 3rem;
  }

  @media (max-width: 992px) {
    .card-img-overlay {
      top: 1rem;
      left: 1rem;
    }
  }

  @media (max-width: 498px) {

    .card-img-overlay {
      top: 0;
      left: 0;

      h1 {
        font-size: 1.25rem;
      }

      .btn {
        font-size: 1rem;
      }

      .btn-lg {
        padding: 0.25rem 0.5rem
      }
    }
  }
}
