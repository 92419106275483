@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';
@import '~ladda/css/ladda';

body {
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";
  //utilities/embed is needed for centering .vjs-big-play-button
  @import "~bootstrap/scss/utilities/embed";

  .video-js {
    @include media-breakpoint-up(md) {
      font-size: 20px;
    }

    .vjs-big-play-button {
      top: calc(50% - .8em);
      left: calc(50% - 1.5em);
    }
  }
}
